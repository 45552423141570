import React from 'react';
import styled from '@emotion/styled';
import {  graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, PageContainer } from 'layouts';

const PostContent = styled.div`
    margin-top: 0;
    a {
        color: ${props => props.theme.colors.link};
        &:hover {
            color: ${props => props.theme.colors.linkHover};
        }
    }
`;

const Page = ({ data }) => {
    const post = data.markdownRemark;
    const { title, cover } = post.frontmatter;
    const html = post.html;

  return (
    <Layout title={title}>
        <PageContainer key={post.id} title={title} cover={cover}>
            <PostContent dangerouslySetInnerHTML={{ __html: html }} />
        </PageContainer>
    </Layout>
  )
}

export default Page;

Page.propTypes = {
    data: PropTypes.object.isRequired,
  };

  export const query = graphql`
      query PageBySlug($slug: String!) {
          markdownRemark(fields: { slug: {eq: $slug } }) {
              html
              id
              frontmatter {
                  title
                  cover {
                    childImageSharp {
                        fluid(maxWidth: 3720) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        }
                    }
                }
              }
          }
      }
  `;